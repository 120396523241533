/******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet Landscape

Needed to change the menu styling from mobile to desktop

******************************************************************/
.mobileOnly {display:none;}
.desktopOnly {display:inline-block;}


h1, .h1, h2, .h2 { font-size: 2em;}
#pushMenu .nav > li > a {font-size: 1.4em;}
 .bigger {font-size: 1.2em;}
.submenu a {font-size: 0.9em;}


#homeMission {font-weight: 700;}

/*************************
Colours
*************************/

/*********************
LAYOUT & GRID STYLES
*********************/
.row, body.Home #content #leftcol > div > div, body.fullWidth #leftcol > div > div{
	padding: 2em 0;
}
body.twoColumns #content #leftcol > div > div{
	padding-bottom: 2em;
}
/* Reduce padding on some rows*/
#breadcrumbs .row {padding: 1em 0;}

/*Increase padding content some rows */

/* Restrict width on some rows and content areas that behave like rows*/

		


/*********************
HEADER STYLES
*********************/

.header {

    .clearHeader {

        > .row {
            min-height: 130px;
        }

        #logo {
            width: 270px;
            height: 98px;
            background-size: 270px 98px;
            @include transition(all 0.3s ease-in-out);
        }
    }

    .darkHeader {
        > .row {
            min-height: 130px;
        }

        #logo {
            width: 270px;
            height: 98px;
            background-size: 270px 98px;
            @include transition(all 0.3s ease-in-out);
        }
    }

    #logo {
    }
}


#content {height:auto;}

body.Home .header {
	/*height: 112.09px;*/
}

.header {

	.row {
		position: relative;
        min-height: 90px;
	}

	#logo {
        transform: none;
	}

    #logo {
        left: 0%;
    }

	#accountMenu {
		right:6%;
        left: auto;
        bottom: 0%;
        top: auto;
        /*> a {margin-right:0.5em;}*/
	}

    
        
    .menu-btn {
        right: 0;
        left: auto;
        bottom: 0%;
        top: auto;
    }
	
}



/**************************
HERO - Home
***************************/

body:not(.Home) #hero {
    height: 450px;
    height: 40vh;

    .topBlock {
        img {
            max-width: 1200px;
        }
    }

    
}/*hero*/


/*********************
NAVIGATION STYLES
*********************/

/*#touchMenu*/


.nav > li.hover > ul { left: 0;}
.nav li li ul { left: -9999px; z-index: 99; position:absolute; }
.nav li li.hover ul { left: 100%; top: 0; z-index: 99; position:absolute; }

/* active state on home - not sure where else to put this for now*/
/* TURN OFF DESKTOP DROPDOWNS*/
.topMenu .submenu {display:none;}

body.Home .clearHeader {

    .topMenu .nav > li > a.activerootmenulink:after, .topMenu .nav > li > a:after, .topMenu .nav > li > a.parent:after {
        background-color: $white;
    }
}

.topMenu {

    .nav {

        > li {
            > a, > a.parent {
                position: relative;
                background: transparent;
                padding: 0.25em 0em;

                &:hover, &:focus {
                    background: none;
             
                }

                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    bottom: 0;
                    left: 0;
                    background-color: $color-primary;
                    visibility: hidden;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    @include transition(all 0.3s ease-in-out 0s);
                }

                &:hover:after, &.activerootmenulink:after {
                    visibility: visible;
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                }
            }
        }

        li {

            a {
                padding: 0.5em 0em;
                background: none;
                border-bottom: none;
            }
        }
    }
}
    /*********************
BLOCK LAYOUT
**********************/

body.Home #homeBlocks {
    position: relative;
    padding-bottom: 5em;
    background: transparent url('../images/slogan.svg') no-repeat bottom 20px right 20px;
    background-size: 280px 280px;
}

#bottomBlocks {
    background: $color-primary url('../images/sloganwhite.svg') no-repeat bottom 30px right 20px;
    background-size: 330px 330px;
}

    .sticky-element {
        position: sticky !important;
        top: 150px;
    }

    .columnlayout.news, .columnlayout.newsBlock {
        margin-bottom: 0em;
    }

    .columnlayout {
        > div, li {
        }

        &.news, &.tips, &.newsBlock {
            > div, > article {
                width: calc(33% - 2em);
            }
        }

        &.services {
            > div, > article {
                width: calc(33% - 2em);
            }
        }
    }

    #gallery li {
        width: 25%;
    }

    .columnlayout.grid-1, .columnlayout.grid-2 {
        > div, > li {
            width: 48%;
        }
    }
    /*Bottom Blocks*/
    #bottomBlocks {

        .block {
            margin-bottom: 2em;
            margin: 0 auto;
        }
    }
    /*layout needed for list items */
    #bottomBlocks #block_26 {

        ul {

            li {
                display: inline-block;
                width: 48%;
                margin-bottom: 0.5em;
            }
        }
    }

    #homeBlocks .columnlayout {
        > div {
            width: 31.5%;
        }

        .block {

            &:nth-child(1) {
                -webkit-animation-delay: 0s; /* Safari 4.0 - 8.0 */
                animation-delay: 0s;
            }

            &:nth-child(2) {
                -webkit-animation-delay: 0.1s; /* Safari 4.0 - 8.0 */
                animation-delay: 0.5s;
            }

            &:nth-child(3) {
                -webkit-animation-delay: 0.2s; /* Safari 4.0 - 8.0 */
                animation-delay: 1s;
            }

            &:nth-child(4) {
                -webkit-animation-delay: 0.3s; /* Safari 4.0 - 8.0 */
                animation-delay: 1.5s;
            }

            &:nth-child(5) {
                -webkit-animation-delay: 0.3s; /* Safari 4.0 - 8.0 */
                animation-delay: 2s;
            }

            &:nth-child(6) {
                -webkit-animation-delay: 0.3s; /* Safari 4.0 - 8.0 */
                animation-delay: 2.5s;
            }
        }
    }



    .services {
        > div, > li, article {
            width: 100%;
        }
    }

    .servicestwo {
        > div, > li, article {
            width: 30%;
        }
    }
    /********************************
GRIDS
**********************************/
    // Relevant styles
    .image-stack, .article-stack {
        display: grid;
        position: relative; // imperative for the overlapping to work
    }

    .image-stack {
        grid-template-columns: repeat(12, 1fr);

        .image-stack__item img {
            width: 100%;
            display: block;
            box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
        }

        .image-stack__item--bottom {
            grid-column: 5 / span 8;
            grid-row: 1;
            -webkit-animation-delay: 0.5s; /* Safari 4.0 - 8.0 */
            animation-delay: 0.5s;
        }

        .image-stack__item--top {
            grid-row: 1;
            grid-column: 1 / span 8;
            padding-top: 20%; // slightly arbitrary, keeps proportion once resized
            z-index: 1; // tells the browser to make this image on top
        }

        &.flip {
            .image-stack__item--bottom {
                grid-column: 1 / span 8;
            }

            .image-stack__item--top {
                grid-column: 5 / span 8;
            }
        }
    }

    .col-2 {
        > div, > li {
            grid-column: span 1;
        }
    }


    .article-stack {
        grid-template-columns: repeat(12, 1fr);

        .article-stack__item img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .article-stack__item--bottom {
            grid-column: 1 / span 9;
            grid-row: 1;
        }

        .article-stack__item--top {
            grid-row: 1;
            grid-column: 8 / span 5;
            margin-top: 10%;
            margin-bottom: 10%; // slightly arbitrary, keeps proportion once resized
            z-index: 1; // tells the browser to make this image on top
            background: $white;
            padding: 15%;
            box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
            -webkit-animation-delay: 0.5s; /* Safari 4.0 - 8.0 */
            animation-delay: 0.5s;
        }
    }
    /* ================================================ */
    /* SLIDESHOWS */
    /* ================================================ */
    /*Full width slider */
    body.Home #hero {
        overflow: hidden;

        .slick-slider {
        }
    }

    ul.responsive3 {
        max-height: none;

        .slick-list {

            .slick-track {

                .slick-slide {
                    /*height:500px;*/
                    &:before {
                        background-color: rgba(0, 0, 0, 0.50);
                    }

                    img {
                        /*height: 420px;*/
                    }

                    .slideshow_caption {
                        text-align: left; /*margin: 0 auto;padding:2em; max-width:50%;*/
                    }
                }
            }
        }

        .slick-slider .slick-active img {
            margin-left: 0;
        }

        .slick-prev, .slick-next {
            top: 50%;
        }

        .slick-next {
            right: 0px;
        }

        .slick-prev {
            left: 0;
        }

        .slick-slider .slick-track, .slick-slider .slick-list {
            -webkit-perspective: 1000px;
        }
    }
    /*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        height: 50px;
        width: 50px;
    }
    /*********************
Sticky CTA
*********************/
    #stickyCTA {
    }

    .contacts-window {
        width: 237px;
        height: auto;
        left: auto;
    }

    body:not(.twoColumns) #leftcol {
        position: relative;
        padding-bottom: 50px;
        background: transparent url('../images/bottomBG.svg') no-repeat bottom -140px right 0px;
        background-size: 1024px 1024px;
    }