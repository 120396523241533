/******************************************************************
Site Name:
Author:

Stylesheet: Mixins & Constants Stylesheet

This is where you can take advantage of Sass' great features:
Mixins & Constants. I won't go in-depth on how they work exactly,
there are a few articles below that will help do that. What I will
tell you is that this will help speed up simple changes like
changing a color or adding CSS3 techniques gradients.

A WORD OF WARNING: It's very easy to overdo it here. Be careful and
remember less is more.

******************************************************************/

/*********************
CLEARFIXIN'
*********************/

// Contain floats: nicolasgallagher.com/micro-clearfix-hack/
.cf {
  zoom: 1;
	&:before, &:after { content: ""; display: table; }
	&:after { clear: both; }
}

/*********************
TOOLS
*********************/

// http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
.image-replacement {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}


/*********************
COLORS
Need help w/ choosing your colors? Try this site out:
http://0to255.com/
*********************/

$black: #231f20;
$white: #fff;

$color-primary: #00703c; /*green*/
$color-secondary: #00bce4; /*black*/
$color-tertiary: #73c167; /*grey*/
$color-quaternary: #c8df8e; 

$link-color:        $color-primary;
$link-hover:        darken($color-secondary, 5%);

$alert-yellow:      #ebe16f;
$alert-red:         #fbe3e4;
$alert-green:       #e6efc2;
$alert-blue:        #d5edf8;

/*
Here's a great tutorial on how to
use color variables properly:
http://sachagreif.com/sass-color-variables/
*/


/*********************
TYPOGRAPHY
*********************/


$sans-serif:        'neue-haas-grotesk-display', sans-serif;
$serif:             serif;
$cursive:           cursive;
$headings:          'ltc-goudy-oldstyle-pro', serif;    



/* 	To embed your own fonts, use this syntax
	and place your fonts inside the
	library/fonts folder. For more information
	on embedding fonts, go to:
	http://www.fontsquirrel.com/
	Be sure to remove the comment brackets.
*/
/*	@font-face {
    	font-family: 'Font Name';
    	src: url('/library/fonts/font-name.eot');
    	src: url('/library/fonts/font-name.eot?#iefix') format('embedded-opentype'),
             url('/library/fonts/font-name.woff') format('woff'),
             url('/library/fonts/font-name.ttf') format('truetype'),
             url('/library/fonts/font-name.svg#font-name') format('svg');
    	font-weight: normal;
    	font-style: normal;
	}
*/



/*
use the best ampersand
http://simplebits.com/notebook/2008/08/14/ampersands-2/
*/
span.amp {
  font-family: Baskerville,'Goudy Old Style',Palatino,'Book Antiqua',serif !important;
  font-style: italic;
}

// text alignment
.text-left   { text-align: left; }
.text-center { text-align: center; }
.text-right  { text-align: right; }


// alerts and notices
%alert {
	margin: 10px;
	padding: 5px 18px;
	border: 1px solid;
}

.alert-help {
	@extend %alert;
	border-color: darken($alert-yellow, 5%);
	background: $alert-yellow;
}

.alert-info {
	@extend %alert;
	border-color: darken($alert-blue, 5%);
	background: $alert-blue;
}

.alert-error {
	@extend %alert;
	border-color: darken($alert-red, 5%);
	background: $alert-red;
}

.alert-success {
	@extend %alert;
	border-color: darken($alert-green, 5%);
	background: $alert-green;
}

/*********************
TRANSITION
*********************/

/*
I totally rewrote this to be cleaner and easier to use.
You'll need to be using Sass 3.2+ for these to work.
Thanks to @anthonyshort for the inspiration on these.
USAGE: @include transition(all 0.2s ease-in-out);

transition-property: property_name; 				
transition-duration: duration; 				
transition-timing-function: timing_function; 				
transition-delay: delay;


*/

@mixin transition($transition...) {
	// defining prefixes so we can use them in mixins below
	$prefixes:      ("-webkit");
  @each $prefix in $prefixes {
    #{$prefix}-transition: $transition;
  }

  transition: $transition;
}

/*needed for when using scroll-down easing to anchor point on page*/

.scrollpoint {
    display: block;
    position: relative;
    top: -110px;
    visibility: hidden;
}

/*********************
Border Radius
*********************/
/*
USAGE: @include border-radius($small-border-radius);
*/

$small-border-radius:	25px;

@mixin border-radius($border-radius) {
	
  	border-radius: $small-border-radius;
}

/*********************
Image zoom
*********************/
/*
USAGE: @include transform($zoom);
*/

$zoom:	scale(1.04);

@mixin transform($zoom) {
	// defining prefixes so we can use them in mixins below
	$prefixes: ("-webkit", "-ms", "-moz", "-o");
	
	@each $prefix in $prefixes {
		#{$prefix}-transform: $zoom;
	}
	
  	transform: $zoom;
}

/*********************
BOX Shadow
*********************/
/*
USAGE: @include box-shadow($boxshadow);
*/

$boxshadow:  inset 0px 1px 28px 0px rgba(0,0,0,1);

@mixin box-shadow($boxshadow) {
	// defining prefixes so we can use them in mixins below
	$prefixes: ("-webkit", "-moz");
	
	@each $prefix in $prefixes {
		#{$prefix}-box-shadow: $boxshadow;
	}
	
  	box-shadow: $boxshadow;
}


/*********************
Align Center
*********************/
/*
USAGE: 
@include center(); 
@include center(true, false);
@include center(false, true); 
*/

@mixin center($horizontal: true, $vertical: true) {
  position: absolute;
  // defining prefixes so we can use them in mixins below
	$prefixes: ("-webkit", "-ms");

  @if ($horizontal and $vertical) {
    top: 49.8%;
    left: 49.8%;
	
	@each $prefix in $prefixes {
		/*#{$prefix}-transform: translate3d(-50.2%, -50.2%, 0);*/
        #{$prefix}-transform: translateX(-50%) translateY(-50%)
	}
	 -ms-transform: translateX(-50%) translateY(-50%);/*correction for IE9*/
    /*transform: translate3d(-50.2%, -50.2%, 0);*/
    transform: translateX(-50%) translateY(-50%);
    

  } @else if ($horizontal) {
    left: 50%;
	
	@each $prefix in $prefixes {
		/*#{$prefix}-transform: translate3d(-50%, 0, 0);*/
        #{$prefix}-transform: translateX(-50%) translateY(0%);
        
	}
	-ms-transform: translateX(-50%) translateY(0%);/*correction*/
    /*transform: translate3d(-50.2%, 0, 0);*/
    transform: translateX(-50%) translateY(0%);

  } @else if ($vertical) {
    top: 50%;
	
	@each $prefix in $prefixes {
		#{$prefix}-transform: translate3d(0, -50.2%, 0);
	}
	-ms-transform: translateX(0%) translateY(-50%);/*correction*/
    transform: translateX(0%) translateY(-50%);
  }


}

/*********************
CSS3 GRADIENTS
Be careful with these since they can
really slow down your CSS. Don't overdo it.
*********************/

/* @include css-gradient(#dfdfdf,#f8f8f8); */
@mixin css-gradient($from: #dfdfdf, $to: #f8f8f8) {
	background-color: $to;
	background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
	background-image: -webkit-linear-gradient(top, $from, $to);
	background-image: -moz-linear-gradient(top, $from, $to);
	background-image: -o-linear-gradient(top, $from, $to);
	background-image: linear-gradient(to bottom, $from, $to);
}

/*********************
BOX SIZING
*********************/

/* NOTE: value of "padding-box" is only supported in Gecko. So
probably best not to use it. I mean, were you going to anyway? */

// BORDER-BOX ALL THE THINGS! (http://paulirish.com/2012/box-sizing-border-box-ftw/)

/* @include box-sizing(border-box); */
@mixin box-sizing($type: border-box) {
	
	// defining prefixes so we can use them in mixins below
	$prefixes:      ("-webkit", "-moz", "-ms");
	@each $prefix in $prefixes {
		#{$prefix}-box-sizing: $type;
	 }

	box-sizing:         $type;
}

// BORDER-BOX ALL THE THINGS! (http://paulirish.com/2012/box-sizing-border-box-ftw/)
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing:    border-box;
	box-sizing:         border-box; 
	
}

/********************************************
MIXIN CREATED FOR WHEN YOU NEED A BREAKPOINT
********************************************/
/*
USAGE: @include bp(baby-screen) {
    width: 100%;
  }
*/

@mixin bp($point) {
  
  $bp-babyscreen: "(min-width: 768px)";
  $bp-mamascreen: "(min-width: 1024px)";
  $bp-papascreen: "(min-width: 1240px)";

  @if $point == papa-screen {
    @media #{$bp-papascreen} { @content; }
  }
  @else if $point == mama-screen {
    @media #{$bp-mamascreen} { @content; }
  }
  @else if $point == baby-screen {
    @media #{$bp-babyscreen}  { @content; }
  }

}

/*********************
MOUSEY
*********************/

.scroll-downs {
  position: absolute;
  /*top: 0;*/
  right: 0;
  bottom: 2%;
  left: 0;
  margin: auto;
  
  width :34px;
  height: 55px;
}
.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}

/*********************
BUTTONS
*********************/
.button, button {
	padding: 0.3em 1.5em 0.3em 1.25em;
	display: inline-block;
	text-decoration: none;
	cursor: pointer;
	border: 1px solid $black;
	background: transparent;
	@include transition(all 0.5s ease-in-out);
	@include border-radius($small-border-radius);
	line-height: 2;
	margin: 0.5em 0;
	border-radius: 2px 2px 25px 2px;

	&:hover, &:focus {
		background: darken($color-primary, 5%);
		border-color: darken($color-primary, 5%);
		@include transition(all 0.5s ease-in-out);
		color: $white;
	}
}

.button.primary, #pushMenu .button {
	background: $color-primary;
	border: none;
	&:hover, &:focus, &:active {
		background: lighten($color-primary, 5%);
		@include transition(all 0.5s ease-in-out);
	}
}

.button.secondary {
	border: 1px solid $color-secondary;
	background: $color-secondary;

	&:hover, &:focus, &:active {
		background: $color-quaternary;
		border-color: $color-quaternary;
		@include transition(all 0.5s ease-in-out);
	}
}

 .button.tertiary {
	border: 1px solid $white;
    background: transparent;
    &:hover, &:focus, &:active {
		background: $white;
        border-color: $white;
		@include transition(all 0.5s ease-in-out);
	}
}

.button.showHide {

	&:before {
		content: "\f107";
		font-family: FontAwesome;
		position: relative;
		float: right;
        margin-left:0.5em;
		font-size: large;
		line-height: 1.6;
		@include transition(all 0.5s ease-in-out);
	}
	&.open {

        background: $color-secondary;
       
		&:before {
			content: "\f106";
            @include transition(all 0.5s ease-in-out);	
		}
	}

  
}
















