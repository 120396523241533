/******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet

Here's where you can start getting into the good stuff.
This size will work on iPads, other tablets, and desktops.
So you can start working with more styles, background images,
and other resources. You'll also notice the grid starts to
come into play. Have fun!

******************************************************************/

/*********************************
Overwrites needed for grid
*********************************/

body.Newsdescription {
    #content .row > #leftcol{width:100%}
    #content .row > #righttcol{display:none;}
}


/******************************************************************
H1, H2, H3, H4, H5 STYLES
******************************************************************/
ul.responsive3 .slideshow_caption p.h1, .heading h1 { font-size: 2em; }
blockquote .h2, body.Home #topblocks h2 { font-size: 1.6em; }
.slideshow_caption a.button, #bottomblocks .button { font-size: 1.1em; }
h3, .h3 {font-size: 1.5em;}

/*********************
GENERAL STYLES
*********************/

body {}


/*********************
LAYOUT & GRID STYLES
*********************/

.row, .row, body.Home #content #leftcol > div > div, body.fullWidth #leftcol > div > div  {
	max-width: 1400px;
}
header .row, #footer .row {
	max-width: 1600px;
}
.row-small  {
	max-width: 1000px;
}


#homeBlocks .row {
	max-width: none;
	width: 98%;
}





/*********************
NAVIGATION STYLES
*********************/


.menuWrap {
	width: 95%;
}
.topMenu {	
	text-align: right;
	a.toggleMenu {
		margin-top: 0.75em;
		padding: 2% 4%;
	}
}

.header #accountMenu a {
	padding: 0.5em 1.25em;
}


/*********************
CONTENT STYLES
*********************/
/* content area all inner pages */
#content {
	.row {
	}
}
	

	
	

blockquote {
	&:before {
		
		float: left;
		font-size: 2em;
	}
}





/*********************
HOME STYLES
*********************/
.custom-shape {
	display: block;
}

.custom-shape svg {
	display: block;
}

.custom-shape {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 50%;
	overflow: hidden;
	line-height: 0;
}

.custom-shape svg {
	position: relative;
	display: block;
	height: 23em;
	width: 195%;
	transform: rotateY(180deg);
	opacity: 0.5;
	right: 0;
}

.custom-shape .shape-fill {
	fill: #FFFFFF;
}

body.Home #content #leftcol > div > div > div.last img

/*********************
LANDING PAGE STYLES
**********************/

body.Landing {
	#content {
		#leftcol {
			> div {
				padding: 4em 0;
			}
		}
	}
}


/*********************
BLOCK LAYOUT
**********************/	

.columnlayout {	
	> div, li {		}
    article.newsBlock {width: 50%;}
}

.columnlayout.servicestwo {
	> div, li {
		width: 95%;
	}

	article.newsBlock {
		width: 50%;
	}
}


/*********************
NEWS LISTING
*********************/
	
div.listingImg { 
	
	float:left; 
	width:30%; 
		
}
	
div.listingTxt { 
	
	float:left; 
	width:70%;
		
}
	
div.caseStudy {
	
	float:left; 
	width: 48%; 
	padding: 1em 0 2em 0; 
	position: relative; 
	margin-right:1.9%
	
}


/*********************
VIDEOS
*********************/

.videoWrapper { 

	margin-left: 0px;
	margin-right: 0px;
	
}

/*************************
THIS IS FOR THE PARALLAX SCROLL
*************************/
#trusticons {

    .parallax-window {
        position: relative;
        padding: 5em 0;
        height: 500px;
        text-align: center;
        ;
        margin-top: 0;
    }
}

/*********************
FOOTER STYLES
*********************/

#footer {
	#copyright {
		.last { text-align: right;}
	}
}

/*
you'll probably need to do quite a bit
of overriding here if you styled them for
mobile. Make sure to double check these!
*/


/*SLIDER*/
/* Bug fix for flashing on Slick slideshow */

/*Full width slider with captions */
    ul.responsive3 {

        .slick-list {

            .slick-track {

                .slick-slide {

                    img {
                        /*height: 520px;*/
						
                    }					
					
                }

            }
        }
		
		.slick-slider .slick-active img{margin-left: -400px;}
    }
	
	

	


/*Fix to prevent last slide flashing*/ .slick-slider .slick-track, .slick-slider .slick-list { -webkit-perspective: 1000px; }



/*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        bottom:1em;
    }
